// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';

export const environment = {
  production: false,
  apiUrls: {
    workflow: 'https://backend.dev.cloud.nimmsta-test.de/api/v1',
    configurations: 'https://backend.dev.configurator.nimmsta-test.de',
  },
  applicationUrls: {
    configurations: 'https://dev.configurator.nimmsta-test.de',
    workflow: 'https://dev.cloud.nimmsta-test.de',
  },
  defaultPageSize: 20,
  defaultPageSizeOptions: [10, 20, 50, 100],
  USER_KEY: 'user_object',
  IMPERSONATION_KEY: 'impersonation_data',
  IMPERSONATION_BROADCAST_CHANNEL_NAME: 'channel-impersonation-data',
  PRODUCT_FRUITS_KEY: 'eSPpYe2bbFm1erLH',
  documentation1Url: 'https://devdocs.nimmsta.com/platform/',
  defaultConfigurationVersion: 7,

  keycloakUrl: 'https://auth.dev.nimmsta-test.de',
  keycloakRealm: 'productivity-cloud',
  keycloakClientId: 'platform',

  discoveryDocument: 'https://discovery.nimmsta.com/index.json',

  androidAppSoftwareId: '17f85f34-1cab-480a-bc17-8050ec26f8d4',
};
